import React from "react";
import { Link } from "gatsby-plugin-intl";
import { concatClassNames as cn } from "@system42/core";
import { ButtonLink, Headline2Sans } from "../../system42";
import * as styles from "./styles.module.css";

export function Intro(props) {
  const {
    title,
    titleWidth,
    content,
    linkTo,
    linkHref,
    linkText,
    image1Src,
    image1Alt,
    image2Src,
    image2Alt,
    statement,
    className,
  } = props;
  return (
    <div className={cn(className, styles.intro)}>
      <Headline2Sans style={{ maxWidth: titleWidth }} className={styles.title}>
        {title}
      </Headline2Sans>
      <div className={styles.columns}>
        <div className={styles.column1}>
          <div>
            <div className={styles.content}>{content}</div>
            {linkText && (
              <ButtonLink
                className={styles.link}
                customTag={linkTo ? Link : "a"}
                to={linkTo}
                href={linkHref}
              >
                {linkText}
              </ButtonLink>
            )}
          </div>
          <img src={image1Src} alt={image1Alt} />
        </div>
        <div className={styles.column2}>
          <img src={image2Src} alt={image2Alt} />
          <div className={styles.statement}>{statement}</div>
        </div>
      </div>
    </div>
  );
}
